import * as React from 'react'
import { Avatar, Box, Divider } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import { useStyles } from '../graphic/MUIStylePresets'

export default function SystemInfo(props) {
  const { t } = useTranslation()
  const classes = useStyles()
  const custom_theme = localStorage.getItem('custom-theme')

  return (
    <Box className={classes.invisible_card}>
      <Box mx="auto" mt="5%" height={200}>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <Avatar src={custom_theme ? '' : props.profile.icon} alt={props.profile.name}>
            {custom_theme ? 'EC' : props.profile.name}
          </Avatar>
          <Box ml={2}>
            <Typography color="secondary" variant="h6">
              {props.profile.name}
            </Typography>
          </Box>
        </Box>
        <Box mt={3}>
          <Typography color="secondary">{t('vc.contact_person')}</Typography>
        </Box>
        <Box width={150}>
          <Divider classes={{ root: classes.divider }} color="primary" />
        </Box>
        <Box mt={1}>
          <Typography color="secondary">{props.profile.contact_person}</Typography>
          <Typography color="secondary">{props.profile.address}</Typography>
        </Box>
      </Box>
    </Box>
  )
}
