import * as React from 'react'
import { Box, Divider, Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { useStyles } from '../graphic/MUIStylePresets'

export default function InstantValueGrid({ content, mobile }) {
  const classes = useStyles()
  return (
    <Box className={classes.invisible_card} my={7} mt={mobile ? 2 : 5} mb={mobile ? 10 : 3}>
      <Grid container justifyContent="space-between">
        {content.map(e => (
          <Grid item xs={3} key={`${e.label}_${e.value}`}>
            <Typography color="secondary" variant={mobile ? 'h6' : 'h4'} align="center">
              {e.value}
            </Typography>
            <Box mb={0.5}>
              <Divider className={classes.divider} />
            </Box>
            <Typography color="secondary" variant="body2" align="center">
              {e.label}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
