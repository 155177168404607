import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import { Backdrop, Button, Card, CardActionArea, Divider } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { useHistory, useLocation } from 'react-router-dom'
import apis from '../../api/api'
import { CircularProgress, Stack } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import ParamDialog from '../../Dialogs/ParamDialog'
import { parseHmm } from '../../utilities/Utils'

const useStyles = makeStyles({
  form: {
    width: '100%' // Fix IE 11 issue.
  },
  card: {
    borderRadius: 19,
    border: '0.25px solid #DCDCDC',
    boxShadow: 'none'
  },
  avatar: {
    width: 12,
    height: 12
  }
})

function ParamReq() {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  const [vc_id] = React.useState(location.state ? location.state.vc : false)
  const [loading, setLoading] = React.useState(true)
  const [connected, setConnected] = React.useState(true)
  const [cmdList, setCmdList] = React.useState([])
  const [paramsList, setParamsList] = React.useState([])
  const [latestTs, setlatestTS] = React.useState([])
  const [openParam, setParamDialog] = React.useState(false)
  const [currentParam, setCurrentParam] = React.useState()

  const handleClientSubmit = paramReq => {
    paramReq.preventDefault()
    const preq = {
      vc_id: parseInt(paramReq.target[0].value),
      cmds: [paramReq.target[2].value]
    }
    apis.addPreq(preq).then(r => {
      console.log(r)
    })
  }
  const handleParamClick = param => {
    setCurrentParam(param)
    setParamDialog(true)
  }

  const handleParmRefresh = vc_id => {
    const cmds = [
      `CMD ${vc_id} 0:PARAM LIST 1 10`,
      `CMD ${vc_id} 0:PARAM LIST 11 20`,
      `CMD ${vc_id} 0:PARAM LIST 21 30`,
      `CMD ${vc_id} 0:PARAM LIST 31 40`,
      `CMD ${vc_id} 0:PARAM LIST 41 50`,
      `CMD ${vc_id} 0:PARAM LIST 51 60`,
      `CMD ${vc_id} 0:PARAM LIST 61 70`,
      `CMD ${vc_id} 0:PARAM LIST 71 80`,
      `CMD ${vc_id} 0:PARAM LIST 81 90`,
      `CMD ${vc_id} 0:PARAM LIST 91 100`,
      `CMD ${vc_id} 0:PARAM LIST 101 110`,
      `CMD ${vc_id} 0:PARAM LIST 111 120`,
      `CMD ${vc_id} 0:PARAM LIST 121`
    ]

    apis.addPreq({ vc_id: vc_id, cmds: cmds }).then()
  }

  const handleDeleteReq = param => {
    const preq = {
      vc_id: vc_id,
      cmd: param
    }
    apis.rmvPendingParam(preq).then(r => {
      console.log(r.status)
    })
  }

  const authCheck = () => {
    const token = localStorage.getItem('token')
    if (token) {
      apis.tokenCheck().then(r => {
        if (!(r.data.isLoggedIn && r.data.user.isAdmin)) {
          history.push('/')
        }
      })
    } else {
      history.push('/')
    }
  }

  const handleParamExport = () => {
    const data = []
    data.push(['IF', 'date', 'param', 'val'])
    paramsList.forEach(param => {
      console.log(param.msg)
      data.push([
        param.id,
        moment(param.timestamp).format('DD/MM/YY HH:mm'),
        param.msg.replaceAll(',', '|').replaceAll('#', ''),
        param.val
      ])
    })
    let csvContent = 'data:text/csv;charset=utf-8,' + data.map(e => e.join(',')).join('\n')
    const content = encodeURI(csvContent)
    var link = document.createElement('a')
    link.setAttribute('href', content)
    link.setAttribute('download', `${moment(new Date()).format('YYmmDD')}_param_export.csv`)
    document.body.appendChild(link) // Required for FF

    link.click() // This will download the data file named "my_data.csv".
  }

  useEffect(() => {
    authCheck()
    const refresh = () => {
      if (vc_id) {
        apis.getPendingParams(vc_id).then(r => {
          if (r.data) {
            setCmdList(r.data)
          }
          apis.getVCParams(vc_id).then(r => {
            if (r.data.length > 0) {
              setlatestTS(r.data[0].timestamp)
              setParamsList(r.data[0].params.sort((a, b) => a.id - b.id))
              setLoading(false)
            } else {
              setConnected(false)
              setLoading(false)
            }
          })
        })
      }
    }
    refresh()
    const interval = setInterval(() => {
      refresh()
    }, 1000)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return (
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  } else {
    return (
      <>
        <Box mt="9%" mx="5%" mb="5%" minWidth={1000}>
          <Box mt={2} mb={1}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography color={'primary'} variant={'h5'}>
                Paramètres du VCS {vc_id} {location.state ? `| ${location.state.name}` : ''}
              </Typography>
              <Button
                variant={'outlined'}
                color={'secondary'}
                onClick={() => history.push('/dashadmin/vcs', { vc: { vc_id: vc_id } })}
              >
                <Typography color={'secondary'}>Retour Aperçu VCS</Typography>
              </Button>
            </Stack>
          </Box>
          <Divider></Divider>
          {!connected && (
            <Box mt={2}>
              <Typography color={'secondary'}>
                Impossible d'afficher les paramètres, le VoltControl n'est pas connecté
              </Typography>
            </Box>
          )}
          <>
            <Box mt={2}>
              <Typography color="secondary">Commandes en attente: {Number(cmdList.length)}</Typography>
            </Box>
            {cmdList.length > 0 &&
              cmdList.map((param, index) => (
                <Box mt={2} key={`${param}_${index}`}>
                  <Card className={classes.card}>
                    <Box m={2}>
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Stack direction="row" spacing={2}>
                          <Typography color="primary">{param}</Typography>
                        </Stack>
                        <Stack>
                          <Button onClick={() => handleDeleteReq(param)}>
                            <Typography color="secondary" variant={'body2'}>
                              annuler
                            </Typography>
                          </Button>
                        </Stack>
                      </Stack>
                    </Box>
                  </Card>
                </Box>
              ))}
            <Box mt={1.5} y={2}>
              <Typography color="secondary">
                {' '}
                Dernière modification: {moment(latestTs).format('DD/MM/YY HH:mm')}
              </Typography>
              <Box mt={4}>
                <Button variant="contained" color="primary">
                  <Typography onClick={() => handleParmRefresh(vc_id)}>Rafraichir les paramètres</Typography>
                </Button>
              </Box>
              <Box mt={2}>
                <Button variant="outlined" color="secondary">
                  <Typography onClick={() => handleParamExport()}>Exporter les paramètres</Typography>
                </Button>
              </Box>
            </Box>
            {paramsList.length > 0 &&
              paramsList.map((param, index) => (
                <Box mt={3} key={`param_${param.id}`}>
                  <Card className={classes.card}>
                    <CardActionArea onClick={() => handleParamClick(param)}>
                      <Box m={2}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                          <Stack direction="row" spacing={2}>
                            <Typography color="secondary">{param.id}</Typography>
                            <Typography
                              color={
                                new Date(latestTs).getTime() - new Date(param.timestamp).getTime() < 360000
                                  ? 'primary'
                                  : 'secondary'
                              }
                            >
                              {moment(param.timestamp).format('DD/MM/YY HH:mm')}
                            </Typography>
                            <Typography color="secondary">{param.msg}</Typography>
                          </Stack>
                          <Typography color="secondary">
                            {/(70|71|72)$/i.test(param.id) ? parseHmm(param.val) : param.val}
                          </Typography>
                        </Stack>
                      </Box>
                    </CardActionArea>
                  </Card>
                </Box>
              ))}
          </>
        </Box>
        )}
        <ParamDialog
          open={openParam}
          handleClose={() => setParamDialog(false)}
          handleClientSubmit={handleClientSubmit}
          param={currentParam}
          vc_id={vc_id}
        ></ParamDialog>
      </>
    )
  }
}

export default ParamReq
