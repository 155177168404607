import { ResponsivePie } from '@nivo/pie'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/material'

function EcoGauge(props) {
  const { t } = useTranslation()
  const data = [
    {
      id: 'value',
      value: props.label ? props.value / props.total : props.value
    },
    {
      id: 'blank',
      value: props.label ? 1 - props.value / props.total : 0.16 - props.value
    }
  ]

  const angle = 70
  const custom_theme = localStorage.getItem('custom-theme')

  return (
    <Stack direction={'column'} spacing={props.mobile ? 7 : 0} justifyContent={'flex-start'}>
      <Box height={props.mobile ? 120 : 200} mt={props.mobile ? -4 : 2}>
        <ResponsivePie
          data={data}
          margin={{ top: 0, right: 80, bottom: 0, left: 80 }}
          startAngle={-70}
          endAngle={angle}
          innerRadius={0.95}
          padAngle={2}
          cornerRadius={22}
          activeOuterRadiusOffset={8}
          colors={custom_theme ? ['#576FF2', '#ffffff'] : ['#a6ce34', '#ffffff']}
          borderWidth={1}
          borderColor={{ from: 'color', modifiers: [['darker', 0]] }}
          enableArcLinkLabels={false}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          enableArcLabels={false}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
          isInteractive={false}
          legends={[]}
        />
      </Box>
      <Box mx="auto" mb={props.mobile ? 7 : 0}>
        {props.variant === 'standard' && (
          <Box mt={-18} mb={10}>
            <Typography variant="h2" align="center" color="secondary">
              {!isNaN(props.value)
                ? props.label
                  ? `${props.value}/${props.total}`
                  : Number(props.value * 100).toPrecision(3)
                : 0}
              {props.label ? '' : '%'}
            </Typography>
            <Typography align="center" color="secondary">
              {props.label ? 'VC Connectés' : t('vc.percent_eco')}
            </Typography>
            <Box mt={2}>
              <Typography color="primary" align={'center'}>
                {props.vc}
              </Typography>
            </Box>
          </Box>
        )}
        {props.variant === 'small' && (
          <Box mt={props.mobile ? -16 : -14}>
            <Typography variant="h4" align="center" color="secondary">
              {!isNaN(props.value) ? Number(props.value * 100).toPrecision(3) : 0}%
            </Typography>
            <Typography align="center" color="secondary" variant={props.mobile ? 'body2' : 'body1'}>
              {t('vc.percent_eco')}
            </Typography>
            {props.mobile && (
              <Box mt={1}>
                <Typography color="primary" align={'center'} variant={'body2'}>
                  {props.vc}
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Stack>
  )
}

export default EcoGauge
