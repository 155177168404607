import { makeStyles } from '@material-ui/core/styles'

const custom_theme = localStorage.getItem('custom-theme')

export const getPrimaryColor = () => {
  switch (custom_theme) {
    case 'sinyon':
      return '#576FF2'
    default:
      return '#a6ce34'
  }
}

export const useStyles = makeStyles({
  form: {
    width: '100%' // Fix IE 11 issue.
  },
  card: {
    borderRadius: 19,
    border: '0.25px solid #DCDCDC',
    boxShadow: 'none'
  },
  invisible_card: {
    borderRadius: 19,
    border: '0.25px solid #FFFFFF',
    boxShadow: 'none'
  },
  avatar: {
    width: 12,
    height: 12
  },
  divider: {
    // Theme Color, or use css color in quote
    background: custom_theme ? getPrimaryColor() : '#a6ce34'
  }
})
