import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import ReactGa from 'react-ga4'
import './utilities/i18n'
import ThemeProvider from '@material-ui/styles/ThemeProvider'
import { createTheme } from '@material-ui/core/styles'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

ReactGa.initialize('G-XEC8C5KBQG')

export const theme = createTheme({
  overrides: {
    MuiAppBar: {
      root: {
        elevation: 10
      }
    },
    MuiButton: {
      root: {
        borderRadius: 28
      },
      contained: {
        boxShadow: 'none'
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1200,
      xl: 1536
    }
  },
  shape: {
    borderRadius: 12
  },
  typography: {
    button: {
      textTransform: 'none'
    },
    h4: {
      fontWeight: 300
    },
    h5: {
      fontWeight: 350,
      fontStyle: 'light'
    },
    h2: {
      fontWeight: 100
    }
  },
  palette: {
    primary: {
      main: '#a6ce34'
    },
    secondary: {
      main: '#9a9a9a'
    },
    background: {
      default: '#ffffff'
    }
  }
})

export const sinyonTheme = createTheme({
  overrides: {
    MuiAppBar: {
      root: {
        elevation: 10
      }
    },
    MuiButton: {
      root: {
        borderRadius: 28
      },
      contained: {
        boxShadow: 'none'
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1200,
      xl: 1536
    }
  },
  shape: {
    borderRadius: 12
  },
  typography: {
    button: {
      textTransform: 'none'
    },
    h4: {
      fontWeight: 300
    },
    h5: {
      fontWeight: 350,
      fontStyle: 'light'
    },
    h2: {
      fontWeight: 100
    }
  },
  palette: {
    primary: {
      main: '#576FF2'
    },
    secondary: {
      main: '#9a9a9a'
    },
    background: {
      default: '#ffffff'
    }
  }
})

const customTheme = localStorage.getItem('custom-theme')

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={customTheme ? sinyonTheme : theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
