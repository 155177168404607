import * as React from 'react'
import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Graph from './Graph'
import { useTranslation } from 'react-i18next'

export default function MiniGraph(props) {
  const dataset = props.dataset
  const custom_theme = localStorage.getItem('custom-theme')
  const unit = props.unit
  const { t } = useTranslation()
  return (
    <>
      <Box height={400}>
        <Graph
          mobile={props.mobile}
          measures={dataset.data}
          min={unit === 'V' ? dataset.min : dataset.min * 0.9}
          max={unit === 'V' ? dataset.max : dataset.max * 1.1}
          format="%H:%M" //yscaleLabel='Puissance Totale (kW)'
          legendOffset={props.mobile ? 80 + dataset.data.length * 17 : 90}
          colors={
            custom_theme
              ? ['#576FF2', '#A457F2', '#57A0F2', '#B4A8F2', '#9a9a9a']
              : ['#9dc183', '#00A86B', '#238b57', '#a6ce34', '#9a9a9a']
          }
          unit={unit}
        />
      </Box>
      {props.mobile ? (
        <Box mt={2} mb={2}>
          <Typography color="secondary" variant={'body2'} align={'center'}>
            {t('vc.mes')}
          </Typography>
          <Typography color="secondary" variant={'body2'} align={'center'}>
            {t('vc.from')} {dataset.data[0].dmin} {t('vc.to')} {dataset.data[0].dmax}
          </Typography>
          <Typography color="secondary" variant={'body2'} align={'center'}>
            Min {dataset.min} {unit} | Max {dataset.max} {unit}
          </Typography>
        </Box>
      ) : (
        <Box mt={-5} mb={2}>
          <Typography color="secondary" variant={'body2'} align={'center'}>
            {t('vc.mes')} {t('vc.from')} {dataset.data[0].dmin} {t('vc.to')} {dataset.data[0].dmax} | Min{' '}
            {dataset.min} {unit} | Max {dataset.max} {unit}
          </Typography>
        </Box>
      )}
    </>
  )
}
